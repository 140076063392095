import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';



const initialState = {
  currentUser: {
    permissions: [],
  },
  organization_uuid: '',
  isLoggedIn: false,
};

// Separate auth reducer
const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (auth, action) => {
      ls('user', action.payload);
      auth.currentUser = action.payload;
      auth.organization_uuid = action.payload.organization_uuid;
      auth.isLoggedIn = true;
    },
    logout: (auth, action) => {
      auth.organization_uuid = action.payload;
    }, // triggers appReducer conditional, resets all state to initialState

    updateCurrentUser: (auth, action) => {
      const payload = {
        ...auth.currentUser,
        ...action.payload,
      };
      ls('user', payload);
      auth.currentUser = payload;
    },

    setOrganizationUUID: (auth, action) => {
      auth.organization_uuid = action.payload;
    },
  },
});

export const {
  login,
  logout,

  updateCurrentUser,

  setOrganizationUUID,
} = slice.actions;

export default slice;



// Logout with cleanup
export const startLogout = () => async (dispatch) => {
  const organization_uuid = ls('user')?.organization_uuid;
  ls.remove('user');
  dispatch(apiSlice.util.resetApiState()); // Clear RTK query cache
  dispatch(logout(organization_uuid)); // triggers appReducer conditional, resets all state to initialState
};



const tags = {
  sessions: 'sessions',
};

const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({
      // Login
      login: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/login/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            const { borrower, refresh_tos, pw_rotation, pw_token } = response.data;

            if (pw_rotation) {
              history.push(`/reset-password/${payload.organization_uuid}/${pw_token}`, { required: true });
              return { data: response.data };
            }

            ls.remove('email');
            api.dispatch(login({ ...borrower, refresh_tos }));
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              ls('email', payload.data.email);
              history.push(`/validate/session/${payload.organization_uuid}`);
            } else {
              toast.error('It looks like something went wrong. Please try again.');
            }
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      // Logout
      logout: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/logout`,
              method: 'GET',
            });
            api.dispatch(startLogout());
            history.push(`/login/${payload}`);
            return { data: response.data };
          } catch (error) {
            api.dispatch(startLogout());
            history.push(`/login/${payload}`);
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get org registration data
      getRegistrationData: build.query({
        queryFn: async (payload, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${payload.organization_uuid}`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Register
      register: build.mutation({
        queryFn: async (payload, api) => {
          try {
            let url = `${baseURL}/register/${payload.organization_uuid}`;
            if (payload.program_uuid) {
              url += `/${payload.program_uuid}`;
            }
            const response = await axios({
              url,
              method: 'POST',
              data: payload.data,
            });
            const { borrower } = response.data;
            ls.remove('email');
            api.dispatch(login({ ...borrower, refresh_tos: false }));
            history.push('/dashboard');
            return { data: response.data };
          } catch (error) {
            if (error.response?.status === 409) {
              ls('email', payload.data.email);
              history.push(`/validate/session/${payload.organization_uuid}`);
              toast.success('Check your email for your code.');
            } else {
              toast.error('It looks like something went wrong. Please try again.');
            }
            return { error: error.message };
          }
        },
      }),



      // Send Forgot Password
      sendForgotPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-email/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate MFA (Multi-Factor Authentication)
      validateMFA: build.mutation({
        queryFn: async (payload, api) => {
          try {
            if (!payload.data.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/validate/session/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            if (response.data.borrower) {
              const { borrower, refresh_tos } = response.data;
              ls.remove('email');
              api.dispatch(login({ ...borrower, refresh_tos })); // Add user data to redux store
              history.push('/dashboard');
            } else {
              toast.warn('Something went wrong, Resend a new code');
            }
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Resend MFA Code
      resendMFACode: build.mutation({
        queryFn: async (payload) => {
          try {
            if (!payload.email) {
              toast.error('No valid email found');
              return { data: 'No email' };
            }
            const response = await axios({
              url: `${baseURL}/resend/mfa/${payload.organization_uuid}`,
              method: 'POST',
              data: { email: payload.email },
            });

            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Validate Password Reset Token
      validatePasswordResetToken: build.query({
        queryFn: async ({ organization_uuid, token_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${organization_uuid}/${token_uuid}`,
              method: 'GET',
              forced: true, // needs to get newly generated token from primary db
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Reset Password
      resetPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${payload.organization_uuid}`,
              method: 'POST',
              data: payload.data,
            });
            ls.remove('user');
            toast.success('Password updated. Please log back in.');
            history.push(`/login/${payload.organization_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Password Complexity
      getPasswordComplexity: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/pw-complexity`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Reset Password Private (Logged In)
      resetPasswordPrivate: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-private`,
              method: 'POST',
              data: payload,
            });
            api.dispatch(startLogout());
            api.dispatch(setOrganizationUUID(response.data.organization_uuid));
            toast.success('Password updated! Please log in again.');
            history.push(`/login/${response.data.organization_uuid}`);
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Update Personal Info
      updatePersonalInfo: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/self`,
              method: 'PUT',
              data: payload,
            });
            api.dispatch(updateCurrentUser(payload));
            toast.success('Personal information updated!');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Send Invite
      sendInvite: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/invites`,
              method: 'POST',
              data: payload.data,
            });
            toast.success(`Thank you. Your invite has been sent to ${payload.data.email}.`);
            payload.cb();
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get My Sessions
      getSessions: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/borrower-sessions/self`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [tags.sessions],
      }),



      // Logout By Session
      logoutBySession: build.mutation({
        queryFn: async (session_uuid) => {
          try {
            const response = await axios({
              url: `${baseURL}/borrower-sessions/self/${session_uuid}`,
              method: 'DELETE',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: [tags.sessions],
      }),



      // Logout All My Sessions
      logoutAllMySessions: build.mutation({
        queryFn: async (data, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/borrower-sessions/self/all`,
              method: 'DELETE',
            });
            api.dispatch(startLogout());
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: [tags.sessions],
      }),



      // Get Current TOS
      getTOS: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/tos/current`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Accept TOS
      acceptTOS: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/tos-agreement`,
              method: 'POST',
              data: payload,
            });
            api.dispatch(updateCurrentUser({ refresh_tos: false }));
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Organization Branding
      getOrganizationBranding: build.query({
        queryFn: async (organization_uuid, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/organizations/${organization_uuid}/branding`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginMutation,
  useLogoutMutation,

  useGetRegistrationDataQuery,
  useRegisterMutation,

  useSendForgotPasswordMutation,
  useValidateMFAMutation,
  useResendMFACodeMutation,

  useValidatePasswordResetTokenQuery,
  useResetPasswordMutation,

  useGetPasswordComplexityQuery,
  useResetPasswordPrivateMutation,

  useUpdatePersonalInfoMutation,

  useSendInviteMutation,

  useGetSessionsQuery,
  useLogoutBySessionMutation,
  useLogoutAllMySessionsMutation,

  useGetTOSQuery,
  useAcceptTOSMutation,

  useGetOrganizationBrandingQuery,
} = authApi;
