import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';


const PrivateRoute = ({ redirectPath, perform, children }) => {
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const organization_uuid = useSelector((state) => state.auth.organization_uuid);

  const location = useLocation();

  let finalRedirectPath = redirectPath;
  if (!finalRedirectPath) {
    finalRedirectPath = organization_uuid ? `/login/${organization_uuid}` : '/';
  }

  // IF NOT LOGGED IN
  if (!isLoggedIn) return <Navigate to={finalRedirectPath} replace from={{ from: location }}/>;


  // CHECK PERMISSIONS
  if (perform) {
    // IF PERFORM IS AN ARRAY
    if (perform instanceof Array) {
      for (let i = 0; i < perform.length; i++) {
        // Passes as long as permissions includes ANY item in the provided array
        if (permissions.includes(perform[i])) return children;
      }
      return <Navigate to={finalRedirectPath} replace />;
    }

    // IF PERFORM IS A STRING
    if (typeof perform === 'string') {
      if (permissions.includes(perform)) return children;
      return <Navigate to={finalRedirectPath} replace />;
    }
  }

  return children;
};


PrivateRoute.propTypes = {
  redirectPath: PropTypes.string,
  perform: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  children: PropTypes.element,
};

export default PrivateRoute;
