import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

// import { Loading, NotFoundPage } from 'bumblebee-ui';
import { Loading } from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));



export const history = createHistory();

const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login/:organization_uuid' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:organization_uuid/:program_uuid?' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session/:organization_uuid' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password/:organization_uuid' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:organization_uuid/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<div className='text-center mt-5'>Unavailable</div>} />

              <Route path='*' element={<div className='text-center mt-5'>Page Not Found</div>} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
      </div>
    </ScrollToTop>
  </CustomRouter>
);

AppRouter.propTypes = {
};

export default AppRouter;
